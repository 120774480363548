<template>
  <PageContent :nav-titles="navTitles">
    <template #header-right>
      <ButtonGroup>
        <ButtonClassic
          variant="solid"
          icon="right"
          @click="copyToClipboard(currentUrl)"
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
      <ButtonClassic
        :label="$t('filtre.filtres')"
        color="primary"
        :variant="showFilterSidebar ? 'solid' : 'ghost'"
        :class="{ active: showFilterSidebar, }"
        :disabled="isLoading"
        icon="right"
        @click="showFilterSidebar = !showFilterSidebar"
      >
        <template #right-icon>
          <UilFilter v-if="!showFilterSidebar" />
          <UilTimes v-else />
        </template>
      </ButtonClassic>
      <ContextMenu
        :bloc-actions-globales="actionsMenu"
        @click-action="handleActions($event)"
      />
    </template>

    <template #aside-content>
      <FilterSidebar
        v-if="showFilterSidebar && allFilters.length"
        :title="$t('recherche.affiner-votre-recherche')"
        :possible-filters="allFilters"
        :active-filters="activeFilters"
        @update="fetch()"
      />
    </template>

    <template #content>
      <EstablishmentRegionDashboard
        :infos-tdb="infosTdb"
        :budget-type="budgetType"
        @change-img="fetchInfosTdbOrganismes()"
      />
      <EstablishmentsRegionTable
        :rows="organismes"
        :active-sort="sort"
        :is-loading="isLoading"
        :budget-type="budgetType"
        @sort="handleSort($event)"
      />
      <div class="pagination-container">
        <div
          v-if="isGE && hasMail"
          id="bouton_export"
          v-tooltip="{
            content: $t('etablissement.export-deja-demande'),
            placement: 'bottom',
            delay: { show: 800, },
            disabled: !exportingStatsMarche,
          }"
        >
          <ButtonClassic
            :label="$t('action.demande-export-xls-stats')"
            variant="special"
            color="primary"
            icon="left"
            :disabled="exportingStatsMarche"
            @click="$store.dispatch('askExportStatsMarche')"
          >
            <template #left-icon>
              <IconChatFileExport />
            </template>
          </ButtonClassic>
        </div>
        <Pagination
          v-if="totalRows > pageSize"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="pageSize"
          @change="(page) => $router.push({ query: { ...$route.query, page, }, })"
        />
      </div>
    </template>
  </PageContent>
</template>

<script>
import {
  ButtonClassic,
  ButtonGroup,
  PageContent,
  Pagination,
  copyToClipboard,
} from "@lde/core_lde_vue";

import EstablishmentRegionDashboard from "@/components/establishments/EstablishmentRegionDashboard.vue";
import EstablishmentsRegionTable from "@/components/establishments/EstablishmentsRegionTable.vue";
import ContextMenu from "@/components/ContextMenu.vue";
import FilterSidebar from "@/components/search/filters/FilterSidebar.vue";
import SearchFilters from "@/mixins/mixinSearchFilters";

import Api from "@/modules/axios";
import { handleSort } from "@/modules/utils";

import config from "@/config";

import { mapGetters } from "vuex";

import IconChatFileExport from "@/components/icons/IconChatFileExport.vue";
import {
  UilFilter,
  UilTimes,
  UilShareAlt,
  UilCommentDots,
} from "@iconscout/vue-unicons";

/**
 * Vue du tableau de bord de l'utilisateur.
 */
export default {
  name: "EtablissementRegion",
  components: {
    PageContent,
    ButtonGroup,
    ButtonClassic,
    Pagination,
    EstablishmentRegionDashboard,
    EstablishmentsRegionTable,
    ContextMenu,
    FilterSidebar,
    IconChatFileExport,
    UilFilter,
    UilTimes,
    UilShareAlt,
  },
  mixins: [SearchFilters],
  data() {
    return {
      currentUrl: window.location.href,
      isLoading: true,
      showFilterSidebar: false,
      navTitles: [
        { label: this.$t("etablissement.tableau-de-bord"), anchorId: "dashboard_overview" },
        { label: this.$t("etablissement.tous-les-etablissements"), anchorId: "establishments_region_table" },
      ],
      infosTdb: {},
      budgetType: "none",
      organismes: [],
      allBudgets: [],
      currentPage: 1,
      totalRows: 0,
      pageSize: 32,
      sort: {
        key: null,
        sortAscended: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "isHorsMarche",
      "hasPerm",
      "isGE",
      "formalizeBudget",
      "exportingStatsMarche",
      "hasMail",
    ]),
    actionsMenu() {
      const blocActions = [
        { actions: [] },
      ];

      if (this.hasPerm("can_view_as_maitre_compta") && this.isGE && this.hasMail && !this.exportingStatsMarche) {
        blocActions[0].actions.push({
          slug: "ask_export",
          label: this.$t("action.demande-export-xls-stats"),
          icon: IconChatFileExport,
        });
      } else if (this.isHorsMarche) {
        blocActions[0].actions.push({
          slug: "ask_desiderata",
          label: this.$t("action.exprimer-desiderata"),
          icon: UilCommentDots,
        });
      }
      return blocActions;
    },
  },
  watch: {
    /**
     * Quand la recherche change dans la queryString
     */
    "$route.query": {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const toNumber = (value) => parseInt(value, 10);

          if (toNumber(newValue.page) !== toNumber(oldValue.page)) {
            this.currentPage = toNumber(newValue.page) || 1;
          }

          if (newValue.sort !== oldValue.sort) {
            this.sort.sortAscended = newValue.sort.charAt(0) !== "-";
            this.sort.key = newValue.sort.replace("-", "");
          }

          this.fetchBudgets().finally(() => {
            this.fetchAllOrganismes();
          });
        }
      },
    },
  },
  mounted() {
    this.fetchInfosTdbOrganismes();

    const { page, sort } = this.$route.query;
    if (page) {
      this.currentPage = parseInt(page, 10);
    }
    if (sort) {
      this.sort.sortAscended = sort.charAt(0) !== "-";
      this.sort.key = sort.replace("-", "");
    }
    this.fetch();
  },
  methods: {
    /**
     * Lance la mise à jour les informations sur le budget et récupère les organismes et filtres.
     */
    fetch() {
      this.fetchBudgets()
        .finally(() => {
          this.fetchFilters()
            .then(() => {
              this.fetchAllOrganismes();
            });
        });
    },
    copyToClipboard,
    handleSort,
    /**
     * Récupère le budget de tous les organismes.
     * @returns {Promise}
     */
    fetchBudgets() {
      return this.$store.dispatch("fetchBudgetRegion")
        .then(({ budgetsOrganismes, type }) => {
          this.budgetType = type === "QUANTITE" ? "licences" : "euros";
          this.allBudgets = budgetsOrganismes;
        }).catch(() => {
          // this.$toast.error({ title: this.$t("dotation.erreur-lors-des-dotations") });
        });
    },
    /**
     * Récupère les infos globales de tous les établissements du compte.
     */
    fetchInfosTdbOrganismes() {
      Api().get("/organisme/infos_tdb/")
        .then((res) => {
          this.infosTdb = { ...res.data, ...this.infosTdb };
        });
    },
    /**
     * Récupère les organismes accessibles avec leur budget pour les afficher dans le tableau.
     * @returns {Promise} Tous les organismes de l'organisme courant.
     */
    fetchAllOrganismes() {
      this.isLoading = true;
      this.showFilterSidebar = false;

      const filtres = this.activeFilters
        .map((filter) => {
          const slug = `${filter.slug}__in`;
          return [slug, filter.options.join(",")];
        });

      const params = {
        page_size: this.pageSize,
        page: this.currentPage,
        ...Object.fromEntries(filtres),
      };

      if (this.sort?.key) {
        params.ordering = `${this.sort.sortAscended ? "" : "-"}${this.sort.key}`;
      }

      let url = "/organisme/filter/";

      if (this.$route.query.etablissements) {
        url += `&id__in=${this.$route.query.etablissements}`;
      }

      return Api().get(url, { params })
        .then((res) => {
          const { results: organismes, count } = res.data;
          this.totalRows = count;
          this.organismes = [];

          organismes.forEach((org, idx) => {
            const budget = this.allBudgets[org.id_organisme.toUpperCase()];
            this.organismes.push({
              tmp_count: idx,
              id: org.id,
              libelle: org.nom_complet,
              uai: org.uai,
              budget: budget ? this.formalizeBudget(budget) : {},
              devis_en_attente: org.devis_en_attente,
              localisation: org.commune_livraison,
              _routeName: "etablissements_tous_item",
            });
          });

          if (this.organismes.length === organismes.length) {
            this.organismes.sort((a, b) => (a.tmp_count - b.tmp_count));
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /**
     * Récupère les filtres des établissements.
     * @returns {Promise} Filtres pour les établissements.
     */
    fetchFilters() {
      return Api().get("/organisme/filter/")
        .then(({ data: { filtres = [] } }) => {
          this.allFilters = filtres;
        });
    },
    /**
     * Appelé lors du clic sur un bouton d'action.
     * @param {Object} action Action cliqué.
     */
    handleActions(action) {
      switch (action.slug) {
        case "ask_export":
          return this.$store.dispatch("askExportStatsMarche");
        case "ask_desiderata":
          return this.contactLde();
        default:
          return null;
      }
    },
    /**
     * Ouvre le client de messagerie pour envoyer un mail à LDE.
     */
    contactLde() {
      window.location.href = `mailto:${config.mails.lde}`;
    },
  },
};
</script>

<style lang="scss">
.pagination-container {
  position: relative;
  #bouton_export {
    position: absolute;
    left: 0;
  }
}
</style>
