<template>
  <section id="dashboard_overview">
    <template v-if="infosTdb && Object.keys(infosTdb).length">
      <div id="global_infos">
        <figure id="img_region">
          <img
            v-if="organismeActuel.infos.image"
            :src="organismeActuel.infos.image"
            :alt="$t('general.alt-image-etablissement')"
          />
          <img
            v-else-if="organismeActuel.infos.image_from_library"
            :src="require(
              `@lde/core_lde_vue/dist/assets/media/etablissements/${organismeActuel.infos.image_from_library}`
            )"
            :alt="$t('general.alt-image-etablissement')"
          />
          <img
            v-else
            :src="require('@lde/core_lde_vue/dist/assets/media/missing_img/no_img_image.svg')"
            :alt="$t('general.alt-image-etablissement')"
          />
          <ButtonClassic
            variant="special"
            :label="$t('action.changer-image')"
            color="primary"
            size="small"
            icon="left"
            @click="$modal.show('modal_change_image')"
          >
            <template #left-icon>
              <IconImage />
            </template>
          </ButtonClassic>
          <ModalChangeImage
            variant="etablissement"
            :user="user"
            :organisme-actuel="organismeActuel"
            :modal-show="showImageModal"
            @validate-img="changeImage($event, organismeActuel)"
            @toggle-modal="showImageModal = $event"
          />
        </figure>
        <div id="infos_base">
          <div id="block_left">
            <DataBloc
              variant="horizontal"
              :label="$tc('etablissement.etablissement-ni-devis-transmis-ni-commande-passee', infosTdb.ORG_NO_TRANSMIS)"
              small
            >
              <template #icon>
                <IconEstablishment />
              </template>
              <template #number>
                {{ infosTdb.ORG_NO_TRANSMIS | spaceNumber }}
              </template>
            </DataBloc>
            <DataBloc
              v-if="infosTdb.TOTAL_DOTATION > 0"
              variant="horizontal"
              :label="$tc('etablissement.etablissements-consomme-totalite-dotation', infosTdb.ORG_DOTATION_ATTEINTE)"
              small
            >
              <template #icon>
                <UilMoneyStack />
              </template>
              <template #number>
                {{ infosTdb.ORG_DOTATION_ATTEINTE | spaceNumber }}
              </template>
            </DataBloc>
          </div>
          <div id="block_right">
            <DataBloc
              :label="$tc('etablissement.etablissement-au-moins-1-devis-transmis', infosTdb.ORG_SOME_TRANSMIS)"
              small
            >
              <template #icon>
                <IconCollegeSmall />
              </template>
              <template #number>
                {{ infosTdb.ORG_SOME_TRANSMIS | spaceNumber }}/{{ infosTdb.ORG_TOTAL | spaceNumber }}
              </template>
            </DataBloc>
            <DataBloc
              :label="total.label"
              small
            >
              <template #icon>
                <UilBalanceScale />
              </template>
              <template #number>
                <span v-html="total.value" />
              </template>
            </DataBloc>
          </div>
        </div>
      </div>

      <div class="grid-zone">
        <div id="commandes">
          <DataBloc
            variant="icon-with-number"
            label="commandes finalisées"
          >
            <template #icon>
              <IconCommande />
            </template>
            <template #number>
              {{ infosTdb.CMD_FINALISEES | spaceNumber }}
            </template>
          </DataBloc>
          <DataBloc
            v-if="budgetType === 'euros'"
            variant="icon-with-number"
            :label="$t('commande.de-commandes-en-cours')"
          >
            <template #icon>
              <IconBudget />
            </template>
            <template #number>
              {{ infosTdb.TOTAL_MONTANT_CMD_EN_COURS | moneyFilter }}{{ "\xa0" }}€
            </template>
          </DataBloc>
          <DataBloc
            v-else-if="budgetType === 'licences'"
            variant="icon-with-number"
            :label="$t('commande.en-cours-de-commande')"
          >
            <template #icon>
              <IconLibrary />
            </template>
            <template #number>
              {{ infosTdb.TOTAL_RESSOURCE_CMD_EN_COURS | spaceNumber }} ex.
            </template>
          </DataBloc>
        </div>
        <div id="devis">
          <DataBloc
            :label="typeMarcheTraduction().etablissementDevisEnAttenteTransmissionRegion"
            variant="icon-with-number"
            small
          >
            <template #icon>
              <IconFileDevisCheck />
            </template>
            <template #number>
              {{ infosTdb.DEVIS_A_SOUMETTRE | spaceNumber }}
            </template>
          </DataBloc>
          <DataBloc
            :label="$t('etablissement.devis-en-attente-de-commande')"
            variant="icon-with-number"
            small
          >
            <template #icon>
              <IconFileDevis />
            </template>
            <template #number>
              {{ infosTdb.DEVIS_A_COMMANDER | spaceNumber }}
            </template>
          </DataBloc>
        </div>
      </div>
    </template>
    <div v-else>
      <InfiniteLoader />
    </div>
  </section>
</template>

<script>
import {
  ButtonClassic,
  InfiniteLoader,
  ModalChangeImage,
  moneyFilter,
} from "@lde/core_lde_vue";

import DataBloc from "@/components/establishments/DataBloc.vue";

import IconEstablishment from "@/components/icons/IconEstablishment.vue";
import IconCollegeSmall from "@/components/icons/IconCollegeSmall.vue";
import IconCommande from "@/components/icons/IconCommande.vue";
import IconBudget from "@/components/icons/IconBudget.vue";
import IconLibrary from "@/components/icons/IconLibrary.vue";
import IconFileDevisCheck from "@/components/icons/IconFileDevisCheck.vue";
import IconFileDevis from "@/components/icons/IconFileDevis.vue";
import IconImage from "@/components/icons/IconImage.vue";

import mixinFicheEts from "@/mixins/mixinEstablishments";

import typeMarcheTraduction from "@/modules/trad_utils";

import { UilMoneyStack, UilBalanceScale } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Affiche les informations globales du tableau de bord.
 */
export default {
  name: "EstablishmentRegionDashboard",
  components: {
    DataBloc,
    ModalChangeImage,
    ButtonClassic,
    InfiniteLoader,
    IconEstablishment,
    IconCollegeSmall,
    IconCommande,
    IconBudget,
    IconLibrary,
    IconFileDevisCheck,
    IconFileDevis,
    IconImage,
    UilMoneyStack,
    UilBalanceScale,
  },
  mixins: [mixinFicheEts],
  props: {
    /**
     * Informations du tableau de bord.
     */
    infosTdb: {
      type: Object,
      required: true,
    },
    /**
     * Type du budget.
     */
    budgetType: {
      type: String,
      validator: (value) => ["none", "euros", "licences"].includes(value),
      required: true,
    },
  },
  data() {
    return {
      showImageModal: false,
    };
  },
  computed: {
    ...mapGetters(["user", "organismeActuel"]),
    total() {
      const total = {
        label: this.$t("etablissement.montant-commande-soumis"),
        value: `${moneyFilter(this.infosTdb.MONTANT_CMD)}&nbsp;€`,
      };

      if (this.infosTdb.TOTAL_DOTATION > 0) {
        total.label += `/${this.$t("etablissement.total-des-dotations")}`;
        total.value += `/${moneyFilter(this.infosTdb.TOTAL_DOTATION)}&nbsp;€`;
      }

      return total;
    },
  },
  methods: {
    moneyFilter,
    typeMarcheTraduction,
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/establishments/establishments_dashboard.scss";
</style>
