<template>
  <section id="establishments_region_table">
    <h2 class="s5 title-separator">
      <span class="bold">{{ $t('etablissement.tous-les-etablissements') }}</span>
    </h2>
    <TableClassic
      v-if="rows?.length && !isLoading"
      :columns="columns"
      :rows="rows"
      :active-sort="activeSort"
      @sort="$emit('sort', $event)"
    >
      <template #uai="{ value, }">
        {{ value.uai }}
      </template>
      <template #libelle="{ value, }">
        {{ value.libelle }}
      </template>
      <template #budget-actuel="{ value, }">
        <div
          v-if="value.budget.dotation"
          class="budget actuel"
          :class="soldeActuelClass(value)"
        >
          <UilBooks
            v-if="value.budget.type === 'LICENCES'"
            size="22"
          />
          <UilEuroCircle
            v-else
            size="22"
          />
          <p>
            {{ $t("dotation.solde-actuel") }}
            <strong v-if="value.budget.type === 'LICENCES'">
              <template v-if="value.budget.marche === 'Grand Est'">
                {{ value.budget.solde | spaceNumber }}
              </template>
              <template v-else>
                {{ value.budget.soldeAVenir | spaceNumber }}
              </template>
            </strong>
            <strong v-else>{{ value.budget.solde | moneyFilter }}{{ "\xa0" }}€</strong>
            {{ $t("general.sur") }}
            <strong v-if="value.budget.type === 'LICENCES'">{{ value.budget.dotation | spaceNumber }}</strong>
            <strong v-else>{{ value.budget.dotation | moneyFilter }}{{ "\xa0" }}€</strong>
          </p>
        </div>
      </template>
      <template #devis-en-attente="{ value, }">
        <Counter
          class="list-counter"
          :value="spaceNumber(value.devis_en_attente)"
        />
      </template>
      <template #budget-commande="{ value, }">
        <div
          v-if="['LICENCES', 'EUROS', ].includes(value.budget.type) && value.budget.dotation"
          class="budget commande"
          :class="soldeAVenirClass(value)"
        >
          <UilBooks
            v-if="value.budget.type === 'LICENCES'"
            size="22"
          />
          <UilEuroCircle
            v-else
            size="22"
          />
          <p>
            {{ $t("dotation.solde-a-venir") }}
            <strong>
              <template v-if="value.budget.type === 'LICENCES'">
                {{ value.budget.soldeAVenir | spaceNumber }}
              </template>
              <template v-else>
                {{ value.budget.soldeAVenir | moneyFilter }}{{ "\xa0" }}€
              </template>
            </strong>
          </p>
        </div>
      </template>
    </TableClassic>
    <div v-else>
      <InfiniteLoader />
    </div>
  </section>
</template>

<script>
import {
  Counter,
  InfiniteLoader,
  spaceNumber,
  percentage,
} from "@lde/core_lde_vue";

import TableClassic from "@/components/table/TableClassic.vue";

import { couleurSelonTaux } from "@/modules/utils";

import { UilEuroCircle, UilBooks } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Affiche tous les établissements de l'utilisateur avec leurs informations.
 */
export default {
  name: "EstablishmentsRegionTable",
  components: {
    Counter,
    InfiniteLoader,
    TableClassic,
    UilEuroCircle,
    UilBooks,
  },
  props: {
    /**
     * Données à afficher dans le tableau.
     */
    rows: {
      type: Array,
      required: true,
    },
    /**
     * Clé et sens du tri.
     */
    activeSort: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Définit si le tableau est en cours de chargement ou non.
     */
    isLoading: {
      type: Boolean,
      default: true,
    },
    /**
     * Type du budget.
     */
    budgetType: {
      type: String,
      validator: (value) => ["none", "euros", "licences"].includes(value),
      required: true,
    },
  },
  emits: [
    /**
     * Déclenché lors du tri.
     */
    "sort",
  ],
  computed: {
    ...mapGetters(["organismeActuel", "isGE", "exportingStatsMarche", "hasMail"]),
    columns() {
      let colBudgetActuel = null;
      let colBudgetCommande = null;

      if (this.budgetType === "euros") {
        colBudgetActuel = {
          key: "budget-actuel",
          name: this.$t("devis-commande.solde-ttc-actuel"),
          notSortable: true,
        };
        colBudgetCommande = {
          key: "budget-commande",
          name: this.$t("devis-commande.solde-ttc-si-commande"),
          notSortable: true,
        };
      } else if (this.budgetType === "licences") {
        colBudgetActuel = {
          key: "budget-actuel",
          name: this.$t("devis-commande.ressources-actuelles"),
          notSortable: true,
        };
        colBudgetCommande = {
          key: "budget-commande",
          name: this.$t("devis-commande.ressources-si-commande"),
          notSortable: true,
        };
      } else {
        // Tant qu'on n'a pas le type de dotation on n'affiche pas les colonnes du tableau
        return [];
      }

      return [
        {
          key: "uai",
          name: this.$t("general.uai"),
          notSortable: true,
        },
        {
          key: "libelle",
          name: this.$t("general.etablissement"),
          sortKey: "id_organisme",
        },
        colBudgetActuel,
        {
          key: "devis-en-attente",
          name: this.$t("devis-commande.devis-en-attente"),
          notSortable: true,
        },
        colBudgetCommande,
      ];
    },
  },
  methods: {
    spaceNumber,
    /**
     * Détermine la classe du solde actuel.
     * @param {Object} Infos de la ligne.
     * @returns {String} Classe HTML.
     */
    soldeActuelClass(ligne) {
      const { budget } = ligne;
      if (!budget || !Object.keys(budget).length) {
        return null;
      }

      const {
        solde,
        marche,
        dotation,
        soldeAVenir,
      } = budget;

      if (solde !== null && solde !== 0) {
        const isGE = marche === "Grand Est";
        const percent = percentage(isGE ? solde : soldeAVenir, dotation);
        return couleurSelonTaux(percent, true);
      }
      return null;
    },
    /**
     * Détermine la classe du solde à venir.
     * @param {Object} Infos de la ligne.
     * @returns {String} Classe HTML.
     */
    soldeAVenirClass(ligne) {
      const { budget } = ligne;
      if (!budget || !Object.keys(budget).length) {
        return "empty";
      }

      const { dotation, soldeAVenir } = budget;
      if (dotation !== 0 && dotation !== null) {
        const percent = percentage(soldeAVenir, dotation);
        return couleurSelonTaux(percent, true);
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/table/establishments_region_table.scss";
</style>
